import { createSlice } from "@reduxjs/toolkit";
import { isNull, isUndefined } from "lodash";

const navSlice = createSlice({
    name: "navItems",
    initialState: {
        navItems: [],
        selectedItem: -1,
        isSidebarOpen: true,
        isSidebarToggle: true,
        isSidebarCollapsed: true
    },
    reducers: {
        updateNavItems: (state, action) => {
            if(!isNull(action.payload.navItems) && !isUndefined(action.payload.navItems))
                state.navItems = action.payload.navItems;

            if(!isNull(action.payload.selectedItem) && !isUndefined(action.payload.selectedItem))
                state.selectedItem = action.payload.selectedItem;

            if(!isNull(action.payload.isSidebarOpen) && !isUndefined(action.payload.isSidebarOpen))
                state.isSidebarOpen = action.payload.isSidebarOpen;

            if(!isNull(action.payload.isSidebarCollapsed) && !isUndefined(action.payload.isSidebarCollapsed))
                state.isSidebarCollapsed = action.payload.isSidebarCollapsed;

            if(!isNull(action.payload.isSidebarToggle) && !isUndefined(action.payload.isSidebarToggle))
                state.isSidebarToggle = action.payload.isSidebarToggle;
        }
    }
});

export const { updateNavItems } = navSlice.actions;
export default navSlice.reducer;
