import { configureStore } from "@reduxjs/toolkit";

import userSlice from "./userSlice";
import navSlice from "./navSlice";

const store = configureStore({
    reducer: {
        userSlice,
        navSlice
    },
    middleware: getDefaultMiddleware => getDefaultMiddleware({ serializableCheck: false })
});

export default store;
