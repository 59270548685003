import React from 'react';
import { Input, Space } from 'antd';

const { Search } = Input;

const SearchComponent = () => {
	const onSearch = (value, _e, info) => console.log(info?.source, value);

	return (
		<Space direction="vertical">
			<Search placeholder="Search for patient" allowClear onSearch={onSearch} className="w-96" />
		</Space>
	);
};
export default SearchComponent;
