import React from 'react';

import notFound404Img from '../../assets/img/404img.png';

import ContentBox from '../Box/ContentBox';

const NotFound = () => {
    return (
        <ContentBox>
            <div className="flex items-start justify-center h-auto min-h-screen py-4">
                <div className="border-hidden border rounded-md flex items-center justify-center md:w-2/3">
                    <div className="flex flex-col items-center ">
                        <img className="px-4 block w-[300px] h-[20vh]" alt="404 Not found.jpeg" src={notFound404Img} />
                        <h1 className="px-4 pt-2 pb-4 text-center text-black text-3xl font-bold leading-10">Looks like you've found the doorway to the great nothing!</h1>
                        <p className="px-4 pb-2 text-base leading-none text-black text-center">The content you’re looking for doesn’t exist. Either it was removed, or you mistyped the link.</p>
                        <p className="px-4 pb-4 text-base leading-none text-black text-center">Sorry about that! Please <b>open the sidebar</b> to get where you need to go...</p>
                    </div>
                </div>
            </div>
        </ContentBox>
    );
}

export default NotFound;
