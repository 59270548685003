import { getAccessToken } from './msalAuth';

export const axiosRequestInterceptor = (axiosInstance) => {
    axiosInstance.interceptors.request.use(async (config) => {
        try {
            const [err, resp] = await getAccessToken();

            if(!err) {
                config.headers['Authorization'] = `Bearer ${resp.accessToken}`;
            }

            return config;
        } catch (error) {
            return Promise.reject(error);
        }
    }, (error) => {
        console.log("error in request interceptor:", error);
        return Promise.reject(error);
    });
}
