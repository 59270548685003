import React from 'react';
import { ChevronDownIcon } from '@heroicons/react/24/outline';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

import { dropdownConfigs, textConfigs } from '../../configs/appConfigs';

const ClinicusDropdown = ({ isOpen, toggle, dropdownClassName = "", displayComponentClassName = "", options = [], onClickOption, displayText = null, size = "md" }) => {
    return (
        <Dropdown isOpen={isOpen} toggle={toggle} className={`${dropdownClassName} w-14 xm:w-24 sm:w-full`}>
            <DropdownToggle size={size} className={`${displayComponentClassName ? displayComponentClassName : dropdownConfigs.colors.defaults.bgClassName} ${textConfigs.colors.defaults.textColorClassName} font-semibold border-[#CCD7E4] border-[1px] border-solid overflow-hidden text-ellipsis`}>
                {displayText?.label || "No option selected"} <ChevronDownIcon className="w-5 h-5" />
            </DropdownToggle>
            <DropdownMenu>
                {options.map(option => (
                    <DropdownItem className="hover:bg-[#c6cfff]" key={option.value} onClick={() => onClickOption(option)}>
                        {option.label}
                    </DropdownItem>
                ))}
            </DropdownMenu>
        </Dropdown>
    );
}

export default ClinicusDropdown;
