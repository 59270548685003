import React from 'react';
import { useSelector } from 'react-redux';

import { validatePermissions } from '../../permissionsList';

import NotFound from '../NotFound/NotFound';

const withAuthorization = (Component, requiredPermissions = {}) => {
    return (props) => {
        const { user } = useSelector(store => store.userSlice);

        if (!user || !validatePermissions(user?.permissions,Object.values(requiredPermissions))) {
            return <NotFound />;
        }

        return <Component {...props} />;
    };
};

export default withAuthorization;
