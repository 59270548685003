import React from 'react';
import { ArrowRightIcon } from '@heroicons/react/24/outline';
import { Button } from 'reactstrap';

import clinicusLogo from '../assets/img/ClinicusLogo.png';
import loginBg from '../assets/img/loginBg.png';

import { loginUser } from '../msalAuth';

const SignIn = () => {
    const handleSignIn = async () => {
        const [err] = await loginUser();

        if(err) {
            console.log(err);
        }
    };

    return (
        <div className="flex w-full gap-10 h-screen">
            <div className="w-2/5 hidden md:block" style={{ background: `url(${loginBg})`, backgroundSize: "100% 100%", backgroundRepeat: "no-repeat"}}>
                <div className="p-6">
                    <div className="font-medium text-xl">
                        <img src={clinicusLogo} alt="Clinicus logo" /> <span>CLINICUS</span>
                    </div>
                    <div className="font-medium text-base text-[#062E55]">A leading solution to enchance Patient Outcomes and improve Provider Efficiency</div>
                </div>
            </div>

            <div className="flex items-center flex-col w-full md:w-max justify-center md:items-start">
                <div className="font-medium text-xl md:hidden">
                    <img src={clinicusLogo} alt="Clinicus logo" /> <span>CLINICUS</span>
                </div>
                <div className="flex flex-col gap-5">
                    <div className="font-bold text-4xl text-[#062E55] w-[350px]">Start your journey with us.</div>
                    <Button className="bg-[#1874DF] flex items-center gap-3 justify-center text-xl font-medium" onClick={handleSignIn}>
                        Login <ArrowRightIcon className="w-5 h-5" />
                    </Button>
                </div>
            </div>
        </div>
    );
}

export default SignIn;
