import to from "await-to-js";

import getAxiosInstance from "./asyncInstance";
import { getDefaultHeaders } from "./asyncUtil";

export const axiosPost = async (url, data = {}) => {
    const defaultHeaders = getDefaultHeaders();
    const instance = getAxiosInstance();

    const [err, res] = await to(
        instance.post(url, data, {
            headers: defaultHeaders
        })
    );

    return [err, res];
}

export const axiosGet = async (url, params, isFile = false) => {
    const defaultHeaders = getDefaultHeaders();
    const instance = getAxiosInstance();

    const instanceBody = {
        params,
        headers: defaultHeaders
    };

    if(isFile) {
        instanceBody["responseType"] = "arraybuffer";
    }

    const [err, res] = await to(instance.get(url, instanceBody));

    return [err, res];
}
