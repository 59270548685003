import { EventType, PublicClientApplication, InteractionRequiredAuthError } from "@azure/msal-browser";

import { KEYS } from "./constants";
import { loginRequest, msalConfig } from "./configs/authConfig";
import { removeFromLocalStorage } from "./utils/localstorage";

export const msalInstance = new PublicClientApplication(msalConfig);

// Default to using the first account if no account is active on page load
if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
	// Account selection logic is app dependent. Adjust as needed for different use cases.
	msalInstance.setActiveAccount(msalInstance.getActiveAccount()[0]);
}

// Listen for sign-in event and set active account
msalInstance.addEventCallback((event) => {
	if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
		const account = event.payload.account;
		msalInstance.setActiveAccount(account);
	}
});

export const loginUser = async () => {
    try {
        await msalInstance.loginRedirect({ ...loginRequest, prompt: 'create' });

        return ["", "success"]; //[err, resp]
    } catch (error) {
        return [error, ""];
    }
}

export const logoutUser = async () => {
    try {
        removeFromLocalStorage(KEYS.ACCESS_TOKEN);
        await msalInstance.logoutRedirect();

        return ["", "success"]; //[err, resp]
    } catch (error) {
        return [error, ""];
    }
}

export const getAccessToken = async () => {
    const activeAccount = msalInstance.getActiveAccount();
    const accessTokenRequest = {
        scopes: ["user.read"],
        account: activeAccount
    };

    try {
        const accessTokenResponse = await msalInstance.acquireTokenSilent(accessTokenRequest);

        // Acquire token silent success
        const accessToken = accessTokenResponse.accessToken;
        const data = {
            accessToken
        };

        return ["", data];
    } catch (silentError) {
        if (silentError instanceof InteractionRequiredAuthError) {
            try {
                const accessTokenResponse = await msalInstance.acquireTokenPopup(accessTokenRequest);

                // Acquire token interactive success
                const accessToken = accessTokenResponse.accessToken;
                const data = {
                    accessToken
                };

                return ["", data];
            } catch (interactiveError) {
                // Acquire token interactive failure
                
                return [interactiveError, null]; 
            }
        } else {
            // Handle other silent error cases
            return [silentError, null];
        }
    }
}
