import React from 'react';
import { useSelector } from 'react-redux';

import { sidebarConfigs } from '../../configs/appConfigs';

const ContentBox = ({ children }) => {
	const { isSidebarOpen, isSidebarCollapsed, isSidebarToggle } = useSelector(state => state.navSlice);

    return (
        <div className="mt-1 w-full flex flex-col flex-wrap overflow-scroll" style={{ marginLeft: isSidebarOpen ? (isSidebarToggle ? sidebarConfigs.collapsedWidth : (isSidebarCollapsed ? sidebarConfigs.collapsedWidth : sidebarConfigs.expandedWidth)) : 0 }}>
            {children}
        </div>
    );
}

export default ContentBox;
