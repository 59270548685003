import React from 'react';
import { BrowserRouter, Route, Routes as Switch, Navigate } from 'react-router-dom';

import SignIn from './loggedoutComponents/SignIn';

const UnauthorisedRoutes = () => {
    return (
        <BrowserRouter>
            <React.Suspense fallback={<div>Loading....</div>}>
                <Switch>
                    <Route path='/sign-in' element={<SignIn />} />
                    <Route path='*' element={<Navigate to="/sign-in" replace />} />
                </Switch>
            </React.Suspense>
        </BrowserRouter>
    );
}

export default UnauthorisedRoutes;
