import { intersection } from "lodash";

export const permissions = {
    homepage: {
        homepageRead: 'CLINICUS_HOMEPAGE_READ',
    },
    patients: {
        patientsRead: 'CLINICUS_PATIENTS_READ',
        patientsEdit: 'CLINICUS_PATIENTS_EDIT'
    }
};

export const validatePermissions = (userPermissions, permissions) => {
    if (userPermissions && permissions) {
        return intersection(userPermissions, permissions).length > 0;
    }
    return false;
}
