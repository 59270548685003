export const sidebarConfigs = {
    collapsedWidth: "70px",
    expandedWidth: "220px",
    defaultPadding: "10px",
    colors: {
        defaults: {
            bgClassName: "bg-[#002E64]",
            activeNavClassName: "bg-[#0463D1]",
            hoverNavClassName: "hover:bg-[#2876cf]",
            textColor: "text-white"
        }
    }
};

export const gridConfigs = {
    colors: {
        defaults: {
            linkFormatterClassName: "text-[#0463D1]",
            rowBgClassName: "bg-white"
        }
    }
}

export const textConfigs = {
    colors: {
        defaults: {
            textColorClassName: "text-[#0467DA]",
            hoverTextColorClassName: "hover:text-[#1874DF]"
        }
    }
}

export const tabConfigs = {
    colors: {
        defaults: {
            inactiveTabBgClassName: "bg-[#E5EFFB]"
        }
    }
}

export const dropdownConfigs = {
    colors: {
        defaults: {
            bgClassName: "bg-[#EFF6FF]",
            borderClassName: "border-[#CCD7E4] border-[1px] border-solid"
        }
    }
}

export const timelineConfigs = {
    colors: {
        defaults: {
            timelineBgClassName: "bg-[#C3C3C3]",
            timelineHoverBgClassName: "bg-[#0463D1]",
            timelineBorderClassName: "border-[#C3C3C3] border-0 border-solid",
            timelineHoverBorderClassName: "border-[#0463D1] border-2 border-solid",
            timelineCircleBgClassName: "bg-[#B2B1B1]",
            timelineXAxisLabelClassName: "text-[#000000]",
            timelineXAxisLabelHoverClassName: "text-[#0463D1]",
            timelineXAxisLabelWeight: "font-semibold"
        }
    }
}

export const iconConfigs = {
    colors: {
        defaults: {
            bgGreen: "bg-[#D8FFF8]",
            iconGreen: "text-[#13CAA9]",
            bgBlue: "bg-[#D3E7FF]",
            iconBlue: "text-[#373780]",
            bgPurple: "bg-[#F0E3FA]",
            iconPurple: "text-[#9351C7]"
        }
    }
}

export const indicatorConfigs = {
    colors: {
        defaults: {
            bgGreen: "bg-[#F0FFEE]",
            textGreen: "text-[#129A0F]"
        }
    }
}

export const dividerConfigs = {
    colors: {
        defaults: {
            borderColor: "border-[#CCD7E4]"
        }
    }
}

export const tooltipConfigs = {
    colors: {
        defaults: {
            bgColorClassName: "bg-[#002E64]",
            textColorClassName: "text-white"
        }
    },
    constantClassnames: "w-fit p-3 font-medium text-sm text-wrap",
}
