import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { ConfigProvider } from 'antd';

import store from './store/store';
import { msalInstance } from './msalAuth';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import './index.css';
import './assets/icons/style.css';

import App from './App';
/**
 * MSAL should be instantiated outside of the component tree to prevent it from being re-instantiated on re-renders.
 * For more, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/getting-started.md
 */

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render (
	// <React.StrictMode> // removed this because this will cause double render of components - read more here https://github.com/reactwg/react-18/discussions/19
	<ConfigProvider theme={{ token: { fontFamily: "Montserrat, sans-serif" } }}>
		<Provider store={store}>
			<App instance={msalInstance} />
		</Provider>
	</ConfigProvider>
	// </React.StrictMode>
);
