export const getFromLocalStorage = (key) => {
    const value = localStorage.getItem(key);

    return value;
}

export const setInLocalStorage = (key, value) => {
    localStorage.setItem(key, value);
}

export const removeFromLocalStorage = (key) => {
    localStorage.removeItem(key);
}
